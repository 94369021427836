<template>
  <AppLayoutVue>
    <div>
      <v-container
        class="index"
        v-if="$store.getters.user && $store.getters.user.subscription"
      >
        <v-row>
          <v-col>
            <v-alert type="success">Your membership is active</v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Payments</h2>
            <v-data-table
              :headers="headers"
              :items="payments"
              :items-per-page="10"
              class="elevation-1"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col>
            <h1>Membership</h1>
            <v-alert type="warning">Your membership is inactive</v-alert>
            <h2>No previous payments</h2>
          </v-col>
        </v-row>
      </v-container>

    </div>
  </AppLayoutVue>
</template>

<script>
import Subscription from "../components/dashboard/Subscription";
import SubscriptionGroup from "../components/dashboard/SubscriptionGroup";
import AppLayoutVue from "../layouts/AppLayout.vue";

import axios from "axios";

export default {
  name: "Index",
  components: { Subscription, SubscriptionGroup, AppLayoutVue },
  comments: {
    Subscription,
  },
  data() {
    return {
      headers: [
        { text: "Text", value: "statusText" },
        { text: "Status", value: "status" },
        { text: "Comment", value: "comment" },
        { text: "Type", value: "type" },
        { text: "Amount", value: "amount" },
        { text: "Date created", value: "dateCreated" },
        { text: "Date paid", value: "datePaid" },
      ],
      payments: [],
    };
  },
  methods: {
    reactivateMembershipHandler() {
      axios
        .post("/api/payment/reactivate", [])
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    if (this.$store.getters.user.subscription) {
      axios
        .get("/api/payment")
        .then((res) => {
          this.payments = res.data.payments.filter(
            (el) => el.status != "initialized"
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
};
</script>

<style scoped></style>
