<template>
  <div>
    <v-row align="center" justify="space-around">
      <v-btn dark :color="color" depressed v-for="btnData in presets" :value="btnData.value" @click="buttonClickHandler">{{btnData.label}}</v-btn>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <v-slider
            v-model="$store.state.donationAmount"
            label="How much?"
            :min="min"
            :max="max"
            :step="step"
            thumb-label="always"
            ticks
        ></v-slider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PricePicker",
  props: ['step', 'min', 'max', 'defaultAmount', 'presets', 'color'],
  data() {
    return {
    }
  },
  methods: {
    buttonClickHandler(event) {
      let amount = event.target.parentElement.getAttribute('value');
      if (!amount) {
        amount = event.target.getAttribute('value')
      }

      this.$store.commit('setDonationAmount', amount);
    }
  }

}
</script>

<style scoped>

</style>