<template>
  <v-container>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel v-for="benefit in benefits" :key="benefit.title">
          <v-expansion-panel-header class="text-left.">
            <v-icon x-large class="d-inline">{{ benefit.icon }}</v-icon>
            {{ benefit.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-left">
            {{ benefit.text }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import Benefit from "./Benefit";
export default {
  name: "Membership",
  components: { Benefit },
  data() {
    return {
      benefits: [
        {
          icon: "mdi-account-multiple",
          title: "Vibrant Community",
          text: "Being part of a supportive community of 350+ active young people, most entrepreneurs and business leaders.",
        },
        {
          icon: "mdi-message-bulleted",
          title: "Private Communication Channels",
          text: "Access to internal communication channels where various professional and social opportunities are shared.",
        },
        {
          icon: "mdi-calendar-check",
          title: "Internal Events",
          text: "Access to internal events with professional and social orientation.",
        },
        {
          icon: "mdi-calendar-star",
          title: "Partner Events",
          text: "Access to partner events.",
        },
        {
          icon: "mdi-brightness-percent",
          title: "Preferential terms",
          text: "Preferential terms of products, services, events or other opportunities.",
        },
        {
          icon: "mdi-email-newsletter",
          title: "Newsletter",
          text: "Receiving a monthly newsletter.",
        },
        {
          icon: "mdi-glass-cocktail",
          title: "Retreats",
          text: "Access to retreat every season.",
        },
        {
          icon: "mdi-lifebuoy",
          title: "Support",
          text: "Reach out to the management team to seek support and benefit from the extensive network of ABLE partners.",
        },
        {
          icon: "mdi-at",
          title: "ABLE email address",
          text: "You receive an ABLE email address with access to various IT resources.",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>