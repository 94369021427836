<template>
  <div>
    <v-row>
      <v-col class="subscription-item" :cols="12/items.length" :sm="12/items.length" v-for="(subscription, index) in items">
        <Subscription
            v-bind="subscription"
            @picked-subscription="subscriptionChange"
            :key="subscription.name"
            :id="index"
            :selected="isSelected(index)"
        />
      </v-col>
    </v-row>

    <PricePicker :color="buttonsColor" v-bind="getPricePickerData()" v-if="includePricePicker"/>
  </div>
</template>

<script>
import Subscription from "./Subscription";
import PricePicker from "./PricePicker";

export default {
  name: "SubscriptionGroup",
  props: ['items', 'includePricePicker'],
  components: {
    Subscription,
    PricePicker,
  },
  data() {
    return {
      selectedIndex: null,
      buttonsColor: 'blue',
    }
  },
  provide() {
    return {
      priceAmount: 0,
    }
  },
  methods: {
    subscriptionChange(selectedIndex) {
      this.selectedIndex = selectedIndex;
      if (this.includePricePicker) {
        this.$store.commit('setDonationAmount', this.items[selectedIndex].defaultAmount);
        this.$store.commit('setDonationType', this.items[selectedIndex].type);
        this.buttonsColor = this.items[selectedIndex].color;
      } else {
        this.$store.commit('setSubscriptionType', this.items[selectedIndex].type);
      }
    },
    isSelected(index) {
      return index === this.selectedIndex;
    },
    getPricePickerData() {
      return this.items[this.selectedIndex];
    }
  },
  mounted() {
    this.items.forEach((element, index) => {
      if (element.selected) {
        this.selectedIndex = index;
      }
    });
    if (this.includePricePicker) {
      this.$store.commit('setDonationType', this.items[this.selectedIndex].type);
    }
  }

}
</script>

<style scoped>
@media only screen and (max-width: 960px) {
  .subscription-item.col-4, .subscription-item.col-sm-4 {
    padding: 2px
  }
}
</style>