import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {},
    subscriptionType: null,
    donationAmount: 0,
    donationType: null,
    loginProcedure: false,
    expirationDate: localStorage.getItem("expirationDate"),
  },
  mutations: {
    login(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
      const expirationDate = new Date(
        new Date().getTime() + 3 * 3600 * 1000
      ).toString();
      state.expirationDate = expirationDate;
      localStorage.setItem("expirationDate", expirationDate);
      state.loginProcedure = false;
    },
    storeUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    clearUser(state) {
      state.user = null;
      localStorage.clear();
    },
    setDonationAmount(state, amount) {
      state.donationAmount = amount;
    },
    setDonationType(state, type) {
      state.donationType = type;
    },
    setSubscriptionType(state, type) {
      state.subscriptionType = type;
    },
    setLoginProcedure(state, loginProcedure) {
      state.loginProcedure = loginProcedure;
    },
    setExpirationDate(state, expirationDate) {
      state.expirationDate = expirationDate;
      localStorage.setItem("expirationDate", expirationDate);
    },
  },
  actions: {
    fetchUser({ commit, state }) {
      if (state.user) {
        return;
      }

      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        state.user = user;
        return;
      }

      axios
        .get("/api/auth/user")
        .then((res) => {
          commit("storeUser", res.data);
        })
        .catch((err) => {
          commit("clearUser");
          router.replace("/");
        });
    },
  },
  modules: {},
  getters: {
    isAuthenticated(state) {
      if (!state.user.name) {
        console.info("No user");
        return false;
      }
      const expirationDate = state.expirationDate;
      if (!expirationDate) {
        console.info("No local storage expiration date");
        return false;
      }
      const now = new Date();
      if (now >= new Date(expirationDate)) {
        console.info("Local storage login expired");
        return false;
      }

      return true;
      return state.user !== null;
    },
    user(state) {
      return state.user;
    },
    donationAmount(state) {
      return state.donationAmount;
    },
    donationType(state) {
      return state.donationType;
    },
    subscriptionType(state) {
      return state.subscriptionType;
    },
    isSubscriptionSelected(state) {
      return state.subscriptionType !== null;
    },
    isMonthlySubscription(state) {
      return state.subscriptionType === "monthly";
    },
    isAnnualSubscription(state) {
      return state.subscriptionType === "annual";
    },
    isLoginProcedure(state) {
      return state.loginProcedure;
    },
    getExpirationDate(state) {
      return state.expirationDate;
    },
  },
});
