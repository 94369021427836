<template>
  <AppLayoutVue>
    <div class="payment-callback">
      <v-container>
        <v-row>
          <v-col>
            <v-alert dense :type="message.type" :outlined="message.outlined">{{
              message.value
            }}</v-alert>
            <v-progress-linear
              v-if="loader"
              indeterminate
              color="blue"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-btn elevation="2" @click="paymentCallback">
      Payment Callback
    </v-btn>
  </AppLayoutVue>
</template>

<script>
import AppLayoutVue from "../layouts/AppLayout.vue";

import axios from "axios";
export default {
  name: "PaymentCallback",
  components: {
    AppLayoutVue,
  },
  data() {
    return {
      message: {
        value: "Processing your payment",
        type: "info",
        outlined: true,
      },
      loader: true,
    };
  },
  methods: {
    paymentCallback() {
      axios
        .post("/api/payment/callback", this.$route.query)
        .then((res) => {
          if (res.data.url) {
            window.location.href = res.data.url;
          }

          this.message.value = res.data.message;
          this.message.type = "success";
          this.message.outlined = false;
          this.loader = false;
          this.$store.getters.user.subscription = true;
          this.$router.replace("/membership");
        })
        .catch((err) => {
          console.error(err);
          this.message.value = err.response.data.message
            ? err.response.data.message
            : "An error occurred. Please try again later.";
          this.message.type = "error";
          this.message.outlined = false;
          this.loader = false;
        });
    },
  },
  mounted() {
    this.paymentCallback();
  },
};
</script>

<style scoped></style>
