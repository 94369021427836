<template>
  <v-app>
    <v-navigation-drawer app clipped mobile-breakpoint="768" v-model="drawer">
      <v-list-item class="px-2">
      <!-- <v-list-item class="px-2" to="/profile"> -->
        <v-list-item-avatar>
          <v-skeleton-loader
            v-if="!$store.state.user"
            type="card-avatar"
          ></v-skeleton-loader>
          <v-img
            v-if="$store.state.user"
            :src="$store.state.user ? $store.state.user.avatar : ''"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-title class="title">
          <v-skeleton-loader
            v-if="!$store.state.user"
            type="list-item"
          ></v-skeleton-loader>
          {{ $store.state.user ? $store.state.user.name : "" }}
        </v-list-item-title>
        <!-- <v-list-item-action>
          <v-icon>{{ !showUserMenu ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon>
        </v-list-item-action> -->
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <GoogleLogin
              v-if="userType === 'google'"
              :params="params"
              class="text-left"
              :logoutButton="true"
              :onSuccess="onLogout"
              :onFailure="onLogout"
              >Logout</GoogleLogin
            >
            <v-facebook-login
              v-if="userType === 'facebook'"
              app-id="3739991916011353"
              @logout="onLogout"
            ></v-facebook-login>
          </v-list-item-content>
        </v-list-item>
        <template v-if="$store.getters.user.role == 'admin'">
          <v-list-item
            v-for="item in adminItems"
            :key="item.title"
            :to="item.link"
            link
          >
            <v-list-item-icon>
              <v-icon color="red">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="red--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark clipped-left flat>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          class="d-md-none"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-img
          alt="ABLE Logo"
          class="shrink mr-2"
          contain
          src="/img/able-icon.png"
          transition="scale-transition"
          width="40"
          @click="$router.push('/')"
        />

        <h2 style="font-weight: 500" @click="$router.push('/')">
          ABLE Members
        </h2>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import VFacebookLogin from "vue-facebook-login-component";
import GoogleLogin from "vue-google-login";

export default {
  name: "AppLayout",
  components: {
    GoogleLogin,
    VFacebookLogin,
  },
  methods: {
    async onLogout(googleUser) {
      console.log(123)
      localStorage.clear();

      await axios
        .get("/api/auth/logout")
        .then((res) => {
          this.$store.commit("clearUser");
        })
        .catch((err) => {
          console.error(err);
        });

      this.$router.go("/login");
    },
    // logout() {
    //   setTimeout(() => {
    //     this.$router.go("/login");
    //   }, 1000);
    // }
  },
  computed: {
    userType() {
      return !this.$store.getters.user ? null : this.$store.getters.user.type;
    },
  },
  data() {
    return {
      params: {},
      drawer: null,
      showUserMenu: false,
      items: [
        { title: "Home", icon: "mdi-view-dashboard", link: "/" },
        // { title: "Login", icon: "mdi-view-dashboard", link: "/login" },
        // { title: "Membership", icon: "mdi-image", link: "/membership" },
        // { title: "Profile", icon: "mdi-account-circle", link: "/profile" },
        {
          title: "Payment History",
          icon: "mdi-wallet-membership",
          link: "/membership",
        },
        // { title: "Help", icon: "mdi-help-circle-outline", link: "/help" },
      ],
      adminItems: [
        {
          title: "Admin Memberships",
          icon: "mdi-wallet",
          role: "admin",
          link: "/admin/subscriptions",
        },
      ],
    };
  },
  created() {
    if (!this.$store.getters.isLoginProcedure) {
      this.$store.dispatch("fetchUser");
    }
  },
};
</script>

<style scoped>
#app {
  background: revert;
}
</style>
