<template>
  <div
      height="100%"
      class="mx-auto my-2"
      outlined
      shaped
      light
      color="white"
      ripple
      elevation="5"
  >
    <v-card-text>
      <div>
        <div
            class="ma-2"
            outlined
            large
            fab
            color="blue"
        >
        <v-icon x-large>{{ icon }}</v-icon>
        </div>
      </div>
      <div style="text-transform: uppercase"><strong>{{ title }}</strong></div>
      <div>{{ text }}</div>
    </v-card-text>
<!--    <v-divider class="mx-4"></v-divider>-->
  </div>
</template>

<script>
export default {
  name: "Benefit",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Lorem ipsum',
    },
    text: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>