<template>
  <router-view></router-view>
</template>

<script>
import Dashboard from './views/Dashboard.vue';

export default {
  name: 'App',

  components: {
    Dashboard,
  },

  data: () => ({
    //
  }),
  created() {
    // if (!this.$store.getters.getExpirationDate && localStorage.getItem('expirationDate')) {
    //   this.$store.commit('setExpirationDate', localStorage.getItem('expirationDate'));
    // }
  },
  watch: {
    $route: {
      immediate: true,
      // Handles page title
      handler(to, from) {
        document.title = 'ABLE Members';
        document.title += to.name ? ' - ' + to.name : '';
      }
    },
  }
};
</script>
