import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import Profile from "../views/Profile.vue";
import AppLayout from "../layouts/AppLayout";
import Dashboard from "../views/Dashboard";
import PaymentCallback from "../views/PaymentCallback";
import Membership from "../views/Membership";
import store from "../store/index";
import Subscriptions from "../views/admin/Subscriptions";
import Help from "../components/dashboard/Help";

Vue.use(VueRouter);

const routes = [
  { path: "/login", component: Login },
  { path: "/privacy", component: Privacy },
  { path: "/terms", component: Terms },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (store.getters.isLoginProcedure || store.getters.isAuthenticated) {
        next();
      } else {
        // next()
        next("/login");
      }
    },
  },
  {
    path: "/payment/callback",
    name: "Payment",
    component: PaymentCallback,
    beforeEnter(to, from, next) {
      if (store.state.user) {
        next();
      } else {
        next();
        // next('/')
      }
    },
  },
  {
    path: '/membership',
    name: 'Membership',
    component: Membership,
    beforeEnter (to, from, next) {
      const expirationDate = localStorage.getItem('expirationDate');
      if (!expirationDate) {
        console.info('No local storage expiration date');

      }
      const now = new Date();
      if (now >= new Date(expirationDate)) {
        console.info('Local storage login expired');
      }



      if (store.getters.isAuthenticated) {
        next()
      } else {
        // next()
        console.info('Redirected to root (not auth)');
        next('/')
      }
    },
  },
  {
    path: "/admin/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    beforeEnter(to, from, next) {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        // next()
        next("/");
      }
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: AppLayout,
    beforeEnter(to, from, next) {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        next("/");
      }
    },
    children: [
      {
        path: "",
        name: "Help",
        component: Help,
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
