<template>
  <v-row>
    <v-col>
      <v-card :outlined="true" elevation="5" class="pa-4">
        <v-row class="mb-1">
          <v-col class="text-h6">
            Renew your membership
          </v-col>
        </v-row>
        <v-radio-group
          v-model="subscriptionPeriod"
          class="mt-0 mb-4 font-weight-bold"
          row
          @change="changeSubscriptionPeriod"
          :messages="messages"
          :hide-details="true"
        >
          <v-radio label="Annually" value="annual"></v-radio>
          <v-radio label="Monthly" value="monthly" selected></v-radio>
          <!-- <v-radio label="One time" value="one-time"></v-radio> -->
        </v-radio-group>
        <div class="text-subtitle-1">
          <!-- Support us with the amount you choose. -->
        </div>
        <!-- Add tooltip for additional donation amount - 7 lv. -->
        <v-radio-group v-model="subscriptionPrice" row class="mt-0">
          <v-radio
            v-for="pricingEl in currentPricing.slice(0, -1)"
            :value="pricingEl"
            active-class="border"
            class="mb-3"
            :key="pricingEl"
          >
            <template v-slot:label>
              <div>
                <span class="text-body-1 font-weight-medium">{{
                  pricingEl
                }}</span>
                <span class="text-body-2"> BGN</span>
              </div>
            </template>
            <!-- :label="pricingEl + ` <span class='text-h3'>BGN</span>`" -->
            <!-- <label>
              {{pricingEl}}
              <span class="text-h4">BGN</span>
            </label> -->
          </v-radio>
          <v-radio
            label="Other"
            :value="currentPricing[currentPricing.length - 1]"
            class="mb-3 font-weight-medium"
          ></v-radio>
        </v-radio-group>
        <div v-if="hasDifferentPricing" class="mb-3">
          <v-text-field
            v-model="subscriptionPrice"
            label=""
            placeholder=""
            solo
            type="number"
            min="7"
            prefix="BGN"
          ></v-text-field>
          <div v-show="showError" class="warning--text">
            Minimum amount is 10 BGN for monthly and 120 BGN for annual membership.
          </div>
        </div>
        <h4>
          Summary:
        </h4>
        <p class="mb-3" v-if="this.subscriptionPeriod == 'annual'">
          {{subscriptionPrice}} BGN. Billed now and at the beginning of the following years.
        </p>
        <div  class="mb-3"  v-else>
          <p>
            Staggered payment option. 
          </p>
          <p>
            {{subscriptionPrice}} BGN. 
            Billed now and on the 5th of every following month. 
          </p>
          <p>
            For any missed payments within the year, your fees will double until you're up-to-date.<br>
            For example, if payments start in April and you missed three months, you'll pay double from April to June, then return to the standard fee in July.
          </p>
        </div>
        <div class="mb-3" v-if="subscriptionPrice != currentPricing[0]">
          Thank you so much for generously going above and beyond what was expected. Your kindness is truly appreciated! 
        </div>

        <p>
          You can check your payment history from the menu.
        </p>

        <v-btn depressed elevation="1" :dark="true" @click="submitDonation"
          >Renew</v-btn
        >
      </v-card>
    </v-col>
    <!-- <v-col
      v-if="this.$store.state.user.subscription"
      cols="12"
      class="text-center"
    >
      Case 1:
      <div>Current Plan: Active</div>
      <div>Automatic Payments: ON</div>
      <div>Next Payment on: 2022 Dec 30</div>
      <v-btn elevation="3" text>Cancel</v-btn>
    </v-col>
    <v-col v-else cols="12" class="text-center">
      Case 2: Current Plan: Non Active (donate to activate)
    </v-col> -->
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      subscriptionPeriod: "annual",
      subscriptionPrice: 120,
      params: {},
      currentPricing: [120, 150, 180, 240, 600, 1000],
      pricing: {
        monthly: [10, 15, 20, 50, 100, 150],
        annual: [120, 150, 180, 240, 600, 1000],
        "one-time": [10, 20, 50, 100, 200],
      },
      otherPrice: "",
      showError: false,
      messages: 'Billed in the beginning of every Year.'
    };
  },
  methods: {
    changeSubscriptionPeriod() {
      this.currentPricing = this.pricing[this.subscriptionPeriod];
      this.subscriptionPrice = this.currentPricing[0];
    },
    // const MONTHLY = 'monthly';
    // const ANNUAL = 'annual';
    // const ONE_TIME = 'one-time';
    submitDonation() {
      if (this.subscriptionPrice == "Other") {
        if (
          (this.subscriptionPeriod === "monthly" && this.otherPrice <= 10) ||
          (this.subscriptionPeriod === "annual" && this.otherPrice <= 120)
        ) {
          this.showError = true;
          return;
        }
      }

      this.showError = false;

      const data = {
        subscriptionType: this.subscriptionPeriod,
        donationType: null,
        donationAmount: Number(this.subscriptionPrice) - (this.subscriptionPeriod === 'monthly' ? 10 : 120),
        includePastYear: false,
      };

      axios
        .post("/api/payment", data)
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    hasDifferentPricing () {
      var hasDifferentPrice = this.currentPricing.slice(0, -1).indexOf(this.subscriptionPrice) == -1
      // if (!hasDifferentPrice) {
      //   this.subscriptionPrice = this.subscriptionPrice;
      // }
      return hasDifferentPrice
    }
  }
};
</script>
