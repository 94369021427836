<template>
  <AppLayoutVue>
    <div class="dashboard">
      <v-container>
        <v-row>
          <v-col>
            <h1>
              ABLE Membership Status:

              <span
                v-if="this.$store.state.user.subscription"
                class="green--text ms-2"
              >
                Active
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="secondary" dark v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>We will send you an email if payment fails.</span>
                </v-tooltip>
              </span>
              <!-- Add button for reactivate subscription -->
              <span v-else class="ms-2">
                <span class="red--text">
                  Inactive
                </span>
              </span>
            </h1>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="justify-center">
        <v-row>
          <v-col v-if="!this.$store.state.user.subscription" cols="12" lg="6">
            <SubscriptionV2 />
          </v-col>
          <v-col
            v-else-if="$store.getters.user && this.$store.state.user.failedPayment"
            cols="12"
            lg="6"
          >
            <v-container
              class="index"
            >
              <v-row>
                <v-col>
                  <h1>Membership</h1>
                  <v-alert type="warning"
                    >There is a payment problem. Please reactivate your
                    membership with the payment provider.</v-alert
                  >
                  <v-btn
                    block
                    color="success"
                    @click="reactivateMembershipHandler"
                    >Reactivate Membership</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" lg="6">
            Вярваме в България и работим за едно по-успешно утре. Присъедини се.
            Вярваме в силата на една общност, работеща за това да изгражда
            лидери и предприемачи. Като месечен дарител помагаш повече българи
            да имат възможности за развитие. Ти си връзката!
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <!-- <Benefits /> -->
      </v-container>
    </div>
  </AppLayoutVue>
</template>

<script>
import axios from "axios";
import Benefits from "../components/dashboard/Benefits";
import Subscription from "../components/dashboard/Subscription";
import SubscriptionGroup from "../components/dashboard/SubscriptionGroup";
import SubscriptionV2 from "../components/dashboard/SubscriptionV2";
import AppLayoutVue from "../layouts/AppLayout.vue";

export default {
  name: "Index",
  components: {
    Benefits,
    Subscription,
    SubscriptionV2,
    SubscriptionGroup,
    AppLayoutVue,
  },
  comments: {
    Subscription,
  },
  data() {
    return {
      membershipStartDate: new Date(new Date().getFullYear(), 0, 1),
      giveMoreSelected: false,
      dontGiveMoreSelected: false,
      includePastYear: false,
      items: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        },
      ],
      subscriptions: [
      {
          name: "Annual",
          type: "annual",
          price: 80,
          currency: "BGN",
          description: ["Billed in the beginning of every Year", "Save 5%"],
          color: "green",
        },
        {
          name: "Monthly",
          type: "monthly",
          price: 7,
          currency: "BGN",
          description: ["Billed in the beginning of every Month"],
          color: "blue",
        },

      ],
      donations: [
        {
          name: "Monthly",
          type: "monthly",
          description: ["Billed in the beginning of every Month"],
          step: 1,
          min: 1,
          selected: true,
          defaultAmount: 5,
          max: 50,
          color: "blue",
          presets: [
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 50, label: 50 },
          ],
        },
        {
          name: "Annual",
          type: "annual",
          step: 5,
          min: 5,
          defaultAmount: 20,
          max: 200,
          description: ["Billed in the beginning of every Year"],
          color: "green",
          presets: [
            { value: 20, label: 20 },
            { value: 50, label: 50 },
            { value: 80, label: 80 },
          ],
        },
        {
          name: "One Time",
          type: "one-time",
          step: 5,
          min: 10,
          defaultAmount: 50,
          max: 2000,
          description: ["Billed once"],
          color: "orange",
          presets: [
            { value: 50, label: 50 },
            { value: 100, label: 100 },
            { value: 200, label: 200 },
            { value: 2000, label: "Велико" },
          ],
        },
      ],
      headers: [
        { text: "Text", value: "statusText" },
        { text: "Status", value: "status" },
        { text: "Comment", value: "comment" },
        { text: "Type", value: "type" },
        { text: "Amount", value: "amount" },
        { text: "Date created", value: "dateCreated" },
        { text: "Date paid", value: "datePaid" },
      ],
      payments: [],
    };
  },
  computed: {
    subscriptionIsSelected() {
      return this.$store.getters.isSubscriptionSelected;
    },
    subscriptionType() {
      return this.$store.getters.subscriptionType;
    },
    donationType() {
      return this.$store.getters.donationType;
    },
    donationAmount() {
      return this.$store.getters.donationAmount;
    },
    monthsToPrePay() {
      return this.monthDiff(this.membershipStartDate, new Date());
    },
  },
  methods: {
    monthDiff(dateFrom, dateTo) {
      return (
        dateTo.getMonth() +
        1 -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      );
    },
    toggleGiveMore() {
      this.giveMoreSelected = true;
      this.dontGiveMoreSelected = false;
      this.$store.commit("setDonationAmount", this.donations[0].defaultAmount);
    },
    toggleDontGiveMore() {
      this.giveMoreSelected = false;
      this.dontGiveMoreSelected = true;
      this.$store.commit("setDonationAmount", 0);
      this.$store.commit("setDonationType", null);
    },
    checkoutHandler() {
      if (!this.subscriptionIsSelected) {
        return null;
      }

      const data = {
        subscriptionType: this.$store.state.subscriptionType,
        donationType: this.$store.state.donationType,
        donationAmount: this.$store.state.donationAmount,
      };

      axios
        .post("/api/payment", data)
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    reactivateMembershipHandler() {
      axios
        .post("/api/payment/reactivate", [])
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    if (this.$store.getters.user.subscription) {
      axios
        .get("/api/payment")
        .then((res) => {
          this.payments = res.data.payments;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
};
</script>
<style scoped>
i {
  vertical-align: baseline !important;
}
</style>
