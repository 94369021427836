<template>
  <v-card
      :loading="loading"
      class="mx-auto my-md-12"
      max-width="450"
      outlined
      shaped
      :dark="selected"
      :light="!selected"
      :color="selected ? color : 'green lighten-5'"
      ripple
      :elevation="elevation"
      @click="clickHandler()"
  >
    <template slot="progress">
      <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title>{{ name }}</v-card-title>
    <v-card-title>{{ price }} {{ currency }}</v-card-title>
    <v-card-text>
      <div v-for="descItem in description">{{ descItem }}</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>


  </v-card>
</template>

<script>
export default {
  name: "Subscription",
  props: {
    id: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    description: {
      type: Array,
      required: true,
    },
    price: {
      type: Number,
      required: false,
    },
    currency: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      elevation: 2,
      loading: false
    }
  },
  methods: {
    clickHandler() {
      this.elevation = 8;
      this.$emit('picked-subscription', this.id)
    }
  }
}
</script>

<style scoped>

</style>